// Note that chainId has to be specified in hex, it's interpreted as hex even if not preceeded by a 0x.

const preloads = {
  'testnet': {
    chainId: '0x61786f6e', // 1635282798
    chainName: 'Axonum Sepolia',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [
      'https://sepolia.axonum.io'
    ],
    blockExplorerUrls: [
      'https://sepolia-explorer.axonum.io'
    ]
  },
}

export default preloads
